<template>
  <!-- HACK: !!!: to be able to use const values in scss -->
  <div
    :style="{
      '--sidebarWidth': `${sidebarWidth}px`,
    }"
  >
    <TrialWarning />

    <router-view />

    <InitialTour />

    <!-- <ReloadPrompt /> -->
  </div>
</template>

<script>
import useBreakpoints from "@/hooks/breakpoints";

import { computed, watch, defineAsyncComponent } from "vue";

import { useRoute, useRouter } from "vue-router";

import { useAuthStore } from "@/stores/auth.js";

import useWSSettings from "@/websockets/ws-settings.js";

// import ReloadPrompt from "@/vue/components/ReloadPrompt.vue";

export default {
  components: {
    InitialTour: defineAsyncComponent(() => import("@/vue/components/tours/InitialTour")),
    TrialWarning: defineAsyncComponent(() => import("@/vue/components/snippets/TrialWarning")),
  },
  setup() {
    useBreakpoints();

    const route = useRoute();
    const router = useRouter();

    const authStore = useAuthStore();
    const isAuthenticated = computed(() => authStore.isAuthenticated);

    // NOTE: open & close done in hook on accessToken change
    const { openWSSettings, closeWSSettings } = useWSSettings();
    if (isAuthenticated.value) {
      openWSSettings();
    } else {
      // ???: ..
      closeWSSettings();
    }
    watch(isAuthenticated, newVal => {
      if (!newVal && route.name !== "route.loginPage") {
        router.push({ name: "route.loginPage" }).catch(() => {
          // ..
        });
      }
    });

    const sidebarWidth = 86;

    // HACK: 100vw causing horizontal overflow sometimes, when the scrollbar is present... use --vws instead
    function vws() {
      const vws = document.documentElement.clientWidth / 100;
      document.documentElement.style.setProperty("--vws", `${vws}px`);
    }
    vws();
    const ro_vws = new ResizeObserver(() => {
      vws();
    });
    ro_vws.observe(document.body); // add elements with potential scroll bar

    return {
      sidebarWidth,
    };
  },
};
</script>

<style lang="scss">
html {
  height: auto;
  min-height: 100%;

  display: flex;
  flex-direction: column;
}

body {
  flex-grow: 1;

  display: flex;
}

#app {
  display: flex;
  flex-direction: column;

  flex-grow: 1;
}
@import "bootstrap/scss/bootstrap";

$bootstrap-icons-font-src:
  url("bootstrap-icons/font/fonts/bootstrap-icons.woff2") format("woff2"),
  url("bootstrap-icons/font/fonts/bootstrap-icons.woff") format("woff");
@import "bootstrap-icons/font/bootstrap-icons";

@import "@/assets/scss/components/v-tippy.scss";
@import "@/assets/scss/components/alerts.scss";
@import "@/assets/scss/components/datepickers.scss";
@import "@/assets/scss/components/multiselect.scss";

@import "bootstrap/scss/bootstrap-utilities";

@include media-breakpoint-down(sm) {
  // HACK: ???: ..
  #smartsupp-widget-container {
    display: none !important;
  }
}
</style>
