import { defineStore } from "pinia";
import { ref } from "vue";
import { useRouter } from "vue-router";

import { useCompaniesStore } from "@/stores/models-companies.js";

import useAlert from "@/hooks/alert.js";

export const useInitialTourStore = defineStore("initialTour", () => {
  const initialTour = ref(undefined);

  function setInitialTour(tour) {
    initialTour.value = tour;
  }

  const { alertError } = useAlert();

  const companiesStore = useCompaniesStore();

  async function completeInitialTour() {
    if (!initialTour.value) return;

    try {
      initialTour.value.finish();
      stopObserver();

      await companiesStore.completeInitialTour();

      document.getElementById("smartsupp-widget-container").style = "display:block";
    } catch (err) {
      console.error(err);
      alertError();
    }
  }

  function startInitialTour() {
    if (!initialTour.value) return;

    initialTour.value.start();
    startObserver();
  }

  const options = {
    useKeyboardNavigation: false,
    labels: {
      buttonSkip: "Oprește",
      buttonPrevious: "Înapoi",
      buttonNext: "Continuă",
      buttonStop: "Finalizează!",
    },
  };

  const router = useRouter();

  // HACK: !!!: wait for the element to be rendered on the page before attaching the popup to it
  function waitForElement(selector) {
    return new Promise((resolve, reject) => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector));
      } else {
        const observer = new MutationObserver(() => {
          if (document.querySelector(selector)) {
            resolve(document.querySelector(selector));
            observer.disconnect();
            clearTimeout(timeoutID);
          }
        });

        observer.observe(document.body, {
          attributes: true,
          childList: true,
          subtree: true,
        });

        const timeoutID = setTimeout(() => {
          reject();
          observer.disconnect();
        }, 10000);
      }
    });
  }

  // HACK: !!!: to trigger popper instance update (by resize event)
  let vueTourNode;
  const observerTargetNode = document.getElementById("app");
  const observerConfig = { attributes: true, childList: true, subtree: true };
  const observerCallback = mutationList => {
    for (const mutation of mutationList) {
      const mutationTarget = mutation.target;

      // !!!: ignore vue tour elements mutations to avoid infinite loops
      if (!vueTourNode.contains(mutationTarget)) window.dispatchEvent(new Event("resize"));
    }
  };
  const observer = new MutationObserver(observerCallback);
  function startObserver() {
    vueTourNode = document.getElementById("initial-tour");
    observer.observe(observerTargetNode, observerConfig);
  }
  function stopObserver() {
    observer.disconnect();
  }

  // HACK: !!!: hide vue tour step elements until their target elements are fully rendered (avoid page jumps of the elements)
  const viewStepElements = ref(false);
  function initialTourNextStep() {
    if (!initialTour.value) return;

    viewStepElements.value = false;
    initialTour.value.nextStep();
  }
  function delayViewStepElements() {
    setTimeout(() => {
      viewStepElements.value = true;
    }, 500);
  }

  const steps = [
    {
      target: '[data-initial-tour-step="1"]',
      content: `Serviciile tale sunt aici!`,
      params: {
        highlight: true,
      },
      before: () =>
        new Promise((resolve, reject) => {
          router
            .push({ name: "route.events" })
            .then(() => {
              document.getElementById("smartsupp-widget-container").style = "display:none";
              // NOTE: when there are multiple agendas
              const agendaSelectorElement = document.querySelector(
                '[data-initial-tour-element="agenda-selector"]'
              );
              if (agendaSelectorElement) agendaSelectorElement.click();

              return waitForElement('[data-initial-tour-step="1"]');
            })
            .then(el => {
              delayViewStepElements();
              resolve(el);
            })
            .catch(() => {
              reject();
            });
        }),
    },
    {
      target: '[data-initial-tour-step="2"]',
      content: `Adăuga un serviciu nou!`,
      params: {
        highlight: true,
      },
      before: () =>
        new Promise((resolve, reject) => {
          router
            .push({ name: "route.eventTypes" })
            .then(() => {
              return waitForElement('[data-initial-tour-step="2"]');
            })
            .then(el => {
              delayViewStepElements();
              resolve(el);
            })
            .catch(() => {
              reject();
            });
        }),
    },
    {
      target: '[data-initial-tour-step="3"]',
      content: "Dacă ai terminat, apasă pe butonul de salvare!",
      before: () =>
        new Promise((resolve, reject) => {
          try {
            const el = document.querySelector('[data-initial-tour-step="2"]');
            el.click();

            waitForElement('[data-initial-tour-step="3"]').then(el => {
              delayViewStepElements();
              resolve(el);
            });
          } catch {
            reject();
          }
        }),
    },
    {
      target: '[data-initial-tour-step="4"]',
      // content in template
      params: {
        highlight: true,
      },
      before: () =>
        new Promise((resolve, reject) => {
          try {
            waitForElement('[data-initial-tour-step="4"]').then(el => {
              document.getElementById("smartsupp-widget-container").style = "display:none";
              delayViewStepElements();
              resolve(el);
            });
          } catch {
            reject();
          }
        }),
    },
    {
      target: '[data-initial-tour-step="5"]',
      // content in template
      params: {
        highlight: true,
      },
      before: () =>
        new Promise((resolve, reject) => {
          router
            .push({ name: "route.objects.list" })
            .then(() => {
              return waitForElement('[data-initial-tour-step="5"]');
            })
            .then(el => {
              delayViewStepElements();
              resolve(el);
            })
            .catch(() => {
              reject();
            });
        }),
    },
    {
      target: '[data-initial-tour-step="6"]',
      content: "Dacă ai terminat, apasă pe butonul de salvare!",
      params: {
        highlight: true,
      },
      before: () =>
        new Promise((resolve, reject) => {
          try {
            const el = document.querySelector('[data-initial-tour-step="5"]');
            el.click();

            waitForElement('[data-initial-tour-step="6"]').then(el => {
              delayViewStepElements();
              resolve(el);
            });
          } catch {
            reject();
          }
        }),
    },
    {
      target: '[data-initial-tour-step="7"]',
      content: `Adaugă o progrmare nouă!`,
      params: {
        highlight: true,
      },
      before: () =>
        new Promise((resolve, reject) => {
          try {
            router
              .push({ name: "route.events" })
              .then(() => {
                document.getElementById("smartsupp-widget-container").style = "display:none";
                // NOTE: when there are multiple agendas
                const agendaSelectorElement = document.querySelector(
                  '[data-initial-tour-element="agenda-selector"]'
                );
                if (agendaSelectorElement) agendaSelectorElement.click();

                return waitForElement('[data-initial-tour-step="7"]');
              })
              .then(el => {
                delayViewStepElements();
                resolve(el);
              })
              .catch(() => {
                reject();
              });
          } catch {
            reject();
          }
        }),
    },
    {
      target: '[data-initial-tour-step="8"]',
      content: "Dacă ai terminat, apasă pe butonul de salvare!",
      params: {
        highlight: true,
      },
      before: () =>
        new Promise((resolve, reject) => {
          try {
            const el = document.querySelector('[data-initial-tour-step="7"]');
            el.click();

            waitForElement('[data-initial-tour-step="8"]').then(el => {
              delayViewStepElements();
              resolve(el);
            });
          } catch {
            reject();
          }
        }),
    },
  ];

  return {
    initialTour,
    setInitialTour,

    initialTourOptions: options,
    initialTourSteps: steps,

    startInitialTour,
    completeInitialTour,

    initialTourNextStep,
    viewStepElements,
  };
});
