export const routes = [
  {
    path: "/conectare",
    name: "route.loginPage",
    component: () => import("@/vue/views/auth/LoginPage.vue"),
    meta: { requiresUnauth: true },
  },
  // TODO: Remove signup-page
  {
    path: "/signup-page",
    name: "route.signupPage",
    component: () => import("@/vue/views/auth/SignupPage.vue"),
    meta: { requiresUnauth: true },
  },

  {
    path: "/inregistrare",
    name: "route.signupWizard",
    component: () => import("@/vue/views/auth/SignupWizard.vue"),
    meta: {
      requiresUnauth: true,
    },
  },

  {
    path: "/",
    name: "route.events",
    component: async function () {
      return await import("@/vue/views/EventsView.vue");
    },
    meta: {
      requiresAuth: true,
      requiresSubscriptionPlanConfirmed: true,
      requiresCompanyUserEventsPermissions: true,
    },
  },
  {
    path: "/eveniment/creare",
    name: "route.events.new",
    component: async function () {
      return await import("@/vue/components/events/form/EventForm.vue");
    },
    meta: {
      requiresAuth: true,
      requiresSubscriptionPlanConfirmed: true,
      requiresCompanyUserEventsPermissions: true,
    },
  },
  {
    path: "/eveniment/modificare/:id",
    name: "route.events.edit",
    component: async function () {
      return await import("@/vue/components/events/form/EventForm.vue");
    },
    meta: {
      requiresAuth: true,
      requiresSubscriptionPlanConfirmed: true,
      requiresCompanyUserEventsPermissions: true,
    },
  },
  {
    path: "/indisponibilitate/creare",
    name: "route.unavailability.new",
    component: async function () {
      return await import("@/vue/components/unavailabilities/UnavailabilityForm.vue");
    },
    meta: {
      requiresAuth: true,
      requiresSubscriptionPlanConfirmed: true,
    },
  },
  {
    path: "/indisponibilitate/modificare/:id",
    name: "route.unavailability.edit",
    component: async function () {
      return await import("@/vue/components/unavailabilities/UnavailabilityForm.vue");
    },
    meta: {
      requiresAuth: true,
      requiresSubscriptionPlanConfirmed: true,
    },
  },
  {
    path: "/objects",
    name: "route.objects",
    redirect: { name: "route.objects.list" },
    component: async function () {
      return await import("@/vue/views/EventObjects.vue");
    },
    meta: {
      requiresAuth: true,
      requiresSubscriptionPlanConfirmed: true,
      requiresCompanyUserEventObjectsPermissions: true,
    },
    children: [
      {
        path: "",
        name: "route.objects.list",
        component: async function () {
          return await import("@/vue/components/objects/ObjectsList.vue");
        },
        meta: {
          requiresSubscriptionPlanConfirmed: true,
        },
      },
      {
        path: "new",
        name: "route.objects.new",
        component: async function () {
          return await import("@/vue/components/objects/ObjectForm.vue");
        },
        meta: {
          requiresSubscriptionPlanConfirmed: true,
        },
      },
      {
        path: "edit/:id",
        name: "route.objects.edit",
        component: async function () {
          return await import("@/vue/components/objects/ObjectForm.vue");
        },
        meta: {
          requiresSubscriptionPlanConfirmed: true,
        },
      },
    ],
  },
  {
    path: "/my-account",
    name: "route.myAccount",
    component: async function () {
      return await import("@/vue/views/my-account/MyAccount.vue");
    },
    meta: { requiresAuth: true },
  },
  // {
  //   path: "/user-feedback",
  //   name: "route.user-feedback",
  //   component: async function () {
  //     return await import("@/vue/views/my-account/UserFeedback.vue");
  //   },
  //   meta: { requiresAuth: true, requiresSubscriptionPlanConfirmed: true },
  // },
  {
    path: "/reset-password",
    name: "route.resetPassword",
    component: async function () {
      return await import("@/vue/views/auth/ResetPassword.vue");
    },
    meta: { requiresUnauth: true },
  },
  {
    path: "/public-agendas/:agendaSlug",
    name: "route.publicAgendas",
    redirect: { name: "route.publicAgendas.eventTypes" },
    component: async function () {
      return await import("@/vue/views/public-agendas/PublicAgendas.vue");
    },
    children: [
      {
        path: "",
        name: "route.publicAgendas.eventTypes", // ???: ..
        component: async function () {
          return await import("@/vue/views/public-agendas/EventTypesView.vue");
        },
      },
      {
        path: ":eventTypeSlug",
        name: "route.publicAgendas.booking", // ???: ..
        component: async function () {
          return await import("@/vue/views/public-agendas/BookingView.vue");
        },
      },
    ],
  },
  {
    path: "/forms/covid/:type/:formUrlId",
    name: "route.forms.covid",
    component: async function () {
      return await import("@/vue/views/forms/CovidForm.vue");
    },
  },
  {
    path: "/forms/gdpr/:type/:formUrlId",
    name: "route.forms.gdpr",
    component: async function () {
      return await import("@/vue/views/forms/GdprForm.vue");
    },
  },
  {
    path: "/forms/cerere-complex-bucuresti/:type/:formUrlId",
    name: "route.forms.cerere-complex-bucuresti",
    component: async function () {
      return await import("@/vue/views/forms/CerereComplexBucurestiForm.vue");
    },
  },
  {
    path: "/forms/gdpr-living-art-studio/:type/:formUrlId",
    name: "route.forms.gdpr-living-art-studio",
    component: async function () {
      return await import("@/vue/views/forms/GdprLivingArtStudio.vue");
    },
  },
  {
    path: "/forms/contract-living-art-studio/:type/:formUrlId",
    name: "route.forms.contract-living-art-studio",
    component: async function () {
      return await import("@/vue/views/forms/ContractLivingArtStudio.vue");
    },
  },
  {
    path: "/pay-invoice/:invoiceHash",
    name: "pay.invoice",
    component: async function () {
      return await import("@/vue/views/payment/PayInvoice.vue");
    },
  },
  {
    path: "/pay-invoice/thank-you",
    name: "pay.invoice.thankYou",
    component: async function () {
      return await import("@/vue/views/payment/PaymentThankYou.vue");
    },
  },
  {
    path: "/new-payment-method/thank-you",
    name: "new.payment.method.thankYou",
    component: async function () {
      return await import("@/vue/views/payment/NewPaymentMethod.vue");
    },
  },
  {
    path: "/new-agenda/thank-you",
    name: "pay.new.agenda.thankYou",
    component: async function () {
      return await import("@/vue/views/payment/NewAdditionalAgendaThankYou.vue");
    },
  },
  {
    path: "/setari",
    name: "route.mySettings",
    component: async function () {
      return await import("@/vue/views/my-settings/MySettings.vue");
    },
    meta: { requiresAuth: true, requiresIsCompanyAdmin: true },
    children: [
      {
        path: "notificari",
        name: "route.mySettings.notification",
        component: async function () {
          return await import("@/vue/components/my-settings/SettingsNotification.vue");
        },
        meta: { requiresSubscriptionPlanConfirmed: true },
      },
      {
        path: "categorii",
        name: "route.mySettings.category",
        component: async function () {
          return await import("@/vue/components/my-settings/SettingsCategories.vue");
        },
        meta: { requiresSubscriptionPlanConfirmed: true },
      },
      {
        path: "planuri",
        name: "route.mySettings.subscription",
        component: async function () {
          return await import("@/vue/components/my-settings/SettingsSubscription.vue");
        },
      },
      {
        path: "plata-online",
        name: "route.mySettings.payments",
        component: async function () {
          return await import("@/vue/components/my-settings/SettingsPayments.vue");
        },
        meta: { requiresSubscriptionPlanConfirmed: true },
      },
      {
        path: "facturi",
        name: "route.mySettings.invoiceProvider",
        component: async function () {
          return await import("@/vue/components/my-settings/SettingsInvoiceProvider.vue");
        },
        meta: { requiresSubscriptionPlanConfirmed: true },
      },
      {
        path: "agenda",
        name: "route.mySettings.agenda",
        component: async function () {
          return await import("@/vue/components/my-settings/SettingsAgenda.vue");
        },
        meta: { requiresSubscriptionPlanConfirmed: true },
      },
      {
        path: "formulare",
        name: "route.mySettings.form",
        component: async function () {
          return await import("@/vue/components/my-settings/SettingsForms.vue");
        },
        meta: { requiresSubscriptionPlanConfirmed: true },
      },
      {
        path: "import",
        name: "route.mySettings.import",
        component: async function () {
          return await import("@/vue/components/my-settings/SettingsImportEventObjects.vue");
        },
        meta: { requiresSubscriptionPlanConfirmed: true },
      },
      {
        path: "subdomeniu",
        name: "route.mySettings.subdomain",
        component: async function () {
          return await import("@/vue/components/my-settings/SettingsSubdomain.vue");
        },
        meta: { requiresSubscriptionPlanConfirmed: true },
      },
      {
        path: "website",
        name: "route.mySettings.website",
        component: async function () {
          return await import("@/vue/components/my-settings/SettingsWebsite.vue");
        },
        meta: { requiresSubscriptionPlanConfirmed: true },
      },
      {
        path: "localizare",
        name: "route.mySettings.localisation",
        component: async function () {
          return await import("@/vue/components/my-settings/SettingsLocalisation.vue");
        },
        meta: { requiresSubscriptionPlanConfirmed: true },
      },
      {
        path: "utilizatori",
        name: "route.mySettings.companyUsers",
        redirect: { name: "route.mySettings.companyUsers.list" },
        component: async function () {
          return await import("@/vue/components/my-settings/SettingsCompanyUsers.vue");
        },
        children: [
          {
            path: "",
            name: "route.mySettings.companyUsers.list",
            component: async function () {
              return await import(
                "@/vue/components/my-settings/company-users-settings/CompanyUsersList.vue"
              );
            },
          },
          {
            path: "creare",
            name: "route.mySettings.companyUsers.new",
            component: async function () {
              return await import(
                "@/vue/components/my-settings/company-users-settings/CompanyUserForm.vue"
              );
            },
          },
          {
            path: "modificare/:id",
            name: "route.mySettings.companyUsers.edit",
            component: async function () {
              return await import(
                "@/vue/components/my-settings/company-users-settings/CompanyUserForm.vue"
              );
            },
          },
        ],
      },
    ],
  },
  {
    path: "/tip-serviciu",
    name: "route.eventTypes",
    redirect: { name: "route.eventTypes.list" },
    component: async function () {
      return await import("@/vue/views/event-types/EventTypesView.vue");
    },
    meta: {
      requiresAuth: true,
      requiresSubscriptionPlanConfirmed: true,
      requiresEventTypeModule: true,
      requiresCompanyUserEventTypesPermissions: true,
    },
    children: [
      {
        path: "",
        name: "route.eventTypes.list",
        component: async function () {
          return await import("@/vue/components/event-types/EventTypesList.vue");
        },
      },
      {
        path: "creare",
        name: "route.eventTypes.new",
        component: async function () {
          return await import("@/vue/components/event-types/form/EventTypeForm.vue");
        },
      },
      {
        path: "modificare/:id",
        name: "route.eventTypes.edit",
        component: async function () {
          return await import("@/vue/components/event-types/form/EventTypeForm.vue");
        },
      },
    ],
  },
  {
    path: "/planner/:hash",
    name: "route.userPlanner",
    component: async function () {
      return await import("@/vue/views/my-settings/UserPlanner.vue");
    },
  },
  {
    path: "/planner/login/:hash",
    name: "route.planner.login",
    component: async function () {
      return await import("@/vue/views/my-settings/PlannerLogin.vue");
    },
  },
  {
    path: "/tip-abonament",
    name: "route.subscriptionTypes",
    redirect: { name: "route.subscriptionTypes.list" },
    component: async function () {
      return await import("@/vue/views/subscription-types/SubscriptionTypeView.vue");
    },
    meta: {
      requiresAuth: true,
      requiresSubscriptionPlanConfirmed: true,
      requiresCompanyUserSubscriptionTypesPermissions: true,
    },
    children: [
      {
        path: "",
        name: "route.subscriptionTypes.list",
        component: async function () {
          return await import("@/vue/components/subscription-types/SubscriptionTypesList.vue");
        },
      },
      {
        path: "creare",
        name: "route.subscriptionTypes.new",
        component: async function () {
          return await import("@/vue/components/subscription-types/SubscriptionTypeForm.vue");
        },
      },
      {
        path: "modificare/:id",
        name: "route.subscriptionTypes.edit",
        component: async function () {
          return await import("@/vue/components/subscription-types/SubscriptionTypeForm.vue");
        },
      },
    ],
  },
  {
    path: "/subscribers",
    name: "route.subscribers",
    redirect: { name: "route.subscribers.list" },
    component: async function () {
      return await import("@/vue/views/subscription/SubscriptionView.vue");
    },
    meta: {
      requiresAuth: true,
      requiresSubscriptionPlanConfirmed: true,
      requiresCompanyUserSubscriptionsPermissions: true,
    },
    children: [
      {
        path: "",
        name: "route.subscribers.list",
        component: async function () {
          return await import("@/vue/components/subscription/list/SubscriptionsList.vue");
        },
      },
      {
        path: "new",
        name: "route.subscribers.new",
        component: async function () {
          return await import("@/vue/components/subscription/list/SubscriptionListItem.vue");
        },
      },
      {
        path: "edit/:id",
        name: "route.subscribers.edit",
        component: async function () {
          return await import("@/vue/components/subscription/list/SubscriptionListItem.vue");
        },
      },
    ],
  },
  {
    path: "/announces",
    name: "route.announces",
    redirect: { name: "route.announces.list" },
    component: async function () {
      return await import("@/vue/views/announces/AnnouncesView.vue");
    },
    meta: {
      requiresAuth: true,
      requiresSubscriptionPlanConfirmed: true,
      requiresCompanyUserAnnouncesPermissions: true,
    },
    children: [
      {
        path: "",
        name: "route.announces.list",
        component: async function () {
          return await import("@/vue/components/announces/AnnouncesList.vue");
        },
        meta: {
          requiresSubscriptionPlanConfirmed: true,
        },
      },
      {
        path: "new",
        name: "route.announces.new",
        component: async function () {
          return await import("@/vue/components/announces/AnnounceForm.vue");
        },
        meta: {
          requiresSubscriptionPlanConfirmed: true,
        },
      },
      {
        path: "edit/:id",
        name: "route.announces.edit",
        component: async function () {
          return await import("@/vue/components/announces/AnnounceForm.vue");
        },
        meta: {
          requiresSubscriptionPlanConfirmed: true,
        },
      },
    ],
  },
  {
    path: "/reports",
    name: "route.reports",
    component: async function () {
      return await import("@/vue/views/reports/ReportsView.vue");
    },
    meta: {
      requiresAuth: true,
      requiresSubscriptionPlanConfirmed: true,
      requiresCompanyUserReportsPermissions: true,
    },
    children: [
      {
        path: "services",
        name: "route.reports.services",
        component: async function () {
          return await import("@/vue/views/reports/ServicesReportsView.vue");
        },
        meta: {
          requiresCompanyUserEventsReportsPermissions: true,
        },
      },
      {
        path: "subscriptions",
        name: "route.reports.subscriptions",
        component: async function () {
          return await import("@/vue/views/reports/SubscriptionsReportsView.vue");
        },
        meta: {
          requiresCompanyUserSubscriptionsReportsPermissions: true,
        },
      },
    ],
  },
  {
    path: "/login-contact-space",
    name: "route.loginContactSpace",
    component: () => import("@/vue/views/auth/LoginContactSpace.vue"),
    meta: { requiresUnauthContactSpace: true },
  },
  {
    path: "/contact-space",
    name: "route.contactSpace",
    component: () => import("@/vue/views/contact-space/ContactSpaceView.vue"),
    meta: { requiresAuthContactSpace: true },
  },

  {
    /**
     * Match all paths
     * uses /:pathMatch(.*)* or /:pathMatch(.*) or /:catchAll(.*)
     */
    path: "/:catchAll(.*)",
    redirect: { name: "route.notFound", replace: false },
  },
  {
    path: "/404",
    name: "route.notFound",
    component: async function () {
      return await import("@/vue/views/NotFound.vue");
    },
  },
];
