const companySettingsNames = Object.freeze({
  SETTING_FREEZE_BEFORE: "freeze_before", // ???: is this used?
  SETTING_NOTIFY_BEFORE_SMS: "notify_before_sms",
  SETTING_NOTIFY_BEFORE_EMAIL: "notify_before_email",
  SETTING_NOTIFY_BEFORE_ALL_DAY_SMS: "notify_before_all_day_sms",
  SETTING_NOTIFY_BEFORE_ALL_DAY_EMAIL: "notify_before_all_day_email",
  SETTING_NOTIFY_OWNER: "notify_owner",
  SETTING_OBJECT_PLACEHOLDER_SINGULAR: "object_placeholder_singular",
  SETTING_OBJECT_PLACEHOLDER_PLURAL: "object_placeholder_plural",
  SETTING_EVENT_TYPE: "event_type",
  SETTING_AGENDA_AVAILABILITY: "agenda_availability",
  SETTING_SMS_MONTHLY_LIMIT: "sms_monthly_limit",
  SETTING_WHATSAPP_MONTHLY_LIMIT: "whatsapp_monthly_limit",
  SETTING_ENABLED_WHATSAPP: "enabled_whatsapp",
  SETTING_ENABLED_SMS: "enabled_sms",
  SETTING_ENABLED_EMAIL: "enabled_email",
  SETTING_FORMS: "enabled_forms",
  SETTING_FORMS_OPTIONS: "forms_options",
  SETTING_LIMIT_BEFORE_NOTIFY: "limit_before_notify",
  SETTING_ENABLED_CUSTOM_EVENT: "enabled_custom_event",
  SETTING_ENABLED_ATTENDANCE: "enabled_attendance",
  SETTING_ENABLED_AGENDA_PLANNER: "enabled_agenda_planner",
  SETTING_FREE_ACCT_LIMIT_EVENTS_MONTH: "free_acct_limit_events_month",
  SETTING_FREE_ACCT_LIMIT_EVENT_TYPES: "free_acct_limit_event_types",
  SETTING_CONFIRMATION_BY_SMS: "confirmation_by_sms",
  SETTING_SMS_TEXT_LENGTH_LIMIT: "sms_text_length_limit",
  SETTING_ENABLED_SMS_TEXT_DIACRITICS: "enabled_sms_text_diacritics",
  SETTING_ENABLED_OWNER_SEND_CUSTOM_SMS: "enabled_owner_send_custom_sms",
  SETTING_ENABLED_MULTIPLE_APPOINTMENTS_PER_HOUR: "enabled_multiple_appointments_per_hour",
  SETTING_ENABLED_EVENT_TYPE_SCHEDULE: "enabled_event_type_schedule",
  SETTING_ENABLED_PUBLIC_WEBSITE: "enabled_public_website",
  SETTING_PUBLIC_WEBSITE_DATA: "public_website_data",
  SETTING_ENABLED_PUBLIC_WEBSITE_ACCESS: "enabled_public_website_access",
  SETTING_ENABLED_MULTIPLE_PARTICIPANTS_PER_EVENT: "enabled_multiple_participants_per_event",
  SETTING_ENABLED_OVERLAPPED_EVENT_GROUPS: "enabled_overalpped_event_groups",
  SETTING_ENABLED_ONLINE_PAYMENTS: "enabled_online_payments",
  SETTING_ENABLED_RECURRENT_ONLINE_PAYMENTS: "enabled_recurrent_online_payments",
  SETTING_ENABLED_RAPORTS: "enabled_raports",
  SETTING_ENABLED_ANNOUNCES: "enabled_announces",
  SETTING_NOTIFICATION_TIME_INTERVAL: "notification_time_interval",
  SETTING_TIMEZONE: "timezone",
  SETTING_ENABLED_PUBLIC_WEBSITE_EMBEDDED_ACCESS: "enabled_public_website_embedded_access",
  SETTING_PUBLIC_WEBSITE_SUBSCRIPTION_DESCRIPTION: "public_website_subscription_description",
  SETTING_FREE_ACCT_LIMIT_SUBSCRIPTION_TYPES: "free_acct_limit_subscription_types",
  SETTING_ENABLED_CATEGORIES: "enabled_categories",
  SETTING_ENABLED_COMPANY_USER: "enabled_company_user",
  SETTING_EVENT_TYPE_PARTICIPANTS_PER_OPTION: "event_type_participants_per_option",
  SETTING_ENABLED_MULTIPLE_SERVICES_PER_EVENT: "setting_enabled_multiple_services_per_event",
  SETTING_CONTACT_HISTORY: "contact_history",
  SETTING_ENABLED_COMPANY_INVOICE_PROVIDER: "enabled_company_invoice_provider",
  SETTING_ACCESS_INVOICE_PROVIDER_SETTINGS: "access_invoice_provider_settings",
});

const reminderTextPlaceholders = Object.freeze({
  OBJECT_NAME: "[NUME]",
  EVENT_TITLE: "[SERVICIU]",
  EVENT_DATE: "[DATA]",
  EVENT_TIME: "[ORA]",
  COMPANY_NAME: "[COMPANIE]", // ???: used?
  COVID_FORM_URL: "[COVID]",
  GDPR_FORM_URL: "[GDPR]",
  CERERE_COMPLEX_BUCURESTI_FORM_URL: "[CERERE-COMPLEX-BUCURESTI]",
  SUBSCRIPTION_TITLE: "[ABONAMENT]",
  SUBSCRIPTION_DATE_END: "[DATA-SFARSIT]",
  SUBSCRIPTION_CATEGORY: "[CATEGORIE]",
  EVENT_DATES: "[DATES]",
  SUBSCRIPTION_DATE_START: "[DATA-START]",
  SITE_PLANIFIC: "[SITE PLANIFIC]",
});

const companySubscriptionLimit = Object.freeze({
  STATUS_AVAILABLE_NOTIFICATIONS: 1,
  STATUS_EXCEDED_NOTIFICATIONS: 0,
  UNLIMITED_NOTIFICATIONS: "-1",
});

const defaultTexts = Object.freeze({
  EMAIL_REMINDER_TEXT: `<p>Salut!</p><p>Ai planificat în data de ${reminderTextPlaceholders.EVENT_DATE} la ora ${reminderTextPlaceholders.EVENT_TIME} evenimentul ${reminderTextPlaceholders.EVENT_TITLE} pentru ${reminderTextPlaceholders.OBJECT_NAME}.</p>`,
  SMS_REMINDER_TEXT: `Salut ${reminderTextPlaceholders.OBJECT_NAME}!\nAi planificat in data de ${reminderTextPlaceholders.EVENT_DATE}, la ora ${reminderTextPlaceholders.EVENT_TIME} evenimentul ${reminderTextPlaceholders.EVENT_TITLE}.`,
  EMAIL_SUBSCRIPTION_REMINDER_TEXT: `<p>Salut ${reminderTextPlaceholders.OBJECT_NAME}!</p><p>Te anunțăm că în ${reminderTextPlaceholders.SUBSCRIPTION_DATE_END} abonamentul tău ${reminderTextPlaceholders.SUBSCRIPTION_TITLE} va expira.</p>`,
  SMS_SUBSCRIPTION_REMINDER_TEXT: `Salut ${reminderTextPlaceholders.OBJECT_NAME}!\nTe anuntam ca in ${reminderTextPlaceholders.SUBSCRIPTION_DATE_END} abonamentul tau ${reminderTextPlaceholders.SUBSCRIPTION_TITLE} va expira.`,
  SMS_EVENT_THANK_YOU_TEXT: `Salut ${reminderTextPlaceholders.OBJECT_NAME}!\nMultumim pentru programare! Te asteptam in ${reminderTextPlaceholders.EVENT_DATE} la ${reminderTextPlaceholders.EVENT_TIME}. Sa ne vedem cu bine! ${reminderTextPlaceholders.COMPANY_NAME}`,
  SMS_EVENT_COMBINED_THANK_YOU_TEXT: `Salut ${reminderTextPlaceholders.OBJECT_NAME}!\n Confirmam programarile urmatoarele: \n ${reminderTextPlaceholders.EVENT_DATES} \n Să ne vedem cu bine! ${reminderTextPlaceholders.COMPANY_NAME}`,
  EMAIL_SUBSCRIPTION_STARTING_TEXT: `Salut ${reminderTextPlaceholders.OBJECT_NAME}, abonamentul tau la ${reminderTextPlaceholders.COMPANY_NAME} a inceput azi, ${reminderTextPlaceholders.SUBSCRIPTION_DATE_START} si se va temina ${reminderTextPlaceholders.SUBSCRIPTION_DATE_END}. Multumim!`,
  SMS_SUBSCRIPTION_STARTING_TEXT: `Salut ${reminderTextPlaceholders.OBJECT_NAME}, abonamentul tau la ${reminderTextPlaceholders.COMPANY_NAME} a inceput azi, ${reminderTextPlaceholders.SUBSCRIPTION_DATE_START} si se va temina ${reminderTextPlaceholders.SUBSCRIPTION_DATE_END}. Multumim!`,
});

const reminderTextPlaceholdersLength = Object.freeze({
  EVENT_DATE: 5,
  EVENT_TIME: 5,
  COVID_FORM_URL: 50,
  GDPR_FORM_URL: 49,
  CERERE_COMPLEX_BUCURESTI_FORM_URL: 69,
});

const reminderTextSmsMaxLength = 480;
const reminderTextSmsPlacehodersLength = 80;

export {
  companySettingsNames,
  reminderTextPlaceholders,
  companySubscriptionLimit,
  defaultTexts,
  reminderTextPlaceholdersLength,
  reminderTextSmsPlacehodersLength,
  reminderTextSmsMaxLength,
};
