import axios from "axios";

import { Links } from "@/api/links.js";

export class User {
  getCompanyUsers() {
    return axios.get(Links.COMPANY_USERS);
  }
  updateCompanyUser(id, data) {
    return axios.put(Links.COMPANY_USER(id), data);
  }
  deleteCompanyUser(id) {
    return axios.delete(Links.COMPANY_USER(id));
  }
}

export const APIUsers = new User();
