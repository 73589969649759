import { defineStore } from "pinia";

import { useSearchStore } from "@/stores/search.js";
import { useEventsStore } from "@/stores/models-events.js";
import { useAgendasStore } from "@/stores/models-agendas.js";
import { useCompanySettingsStore } from "@/stores/models-companySettings.js";
import { useEventTypesStore } from "@/stores/models-eventTypes.js";
import { useSubscriptionPlansStore } from "@/stores/models-subscriptionPlans.js";
import { useSubscriptionsStore } from "@/stores/models-subscriptions.js";
import { useAuthStore } from "@/stores/auth.js";

import { APICompanies } from "@/api/companies.js";

import * as Sentry from "@sentry/vue";
import moment from "moment";
import { companyTypes } from "@/constants/company.js";
import { setMomentDefaultTimezone } from "@/utils/dates";
import { companySettingsNames } from "@/constants/companySettings.js";
import { checkIfIsValidAgendaView, checkIfIsValidMultipleAgendasView } from "@/utils/agendas";
import { useUsersStore } from "./models-users.js";
import useAlert from "@/hooks/alert";

export const useCompaniesStore = defineStore("companies", {
  state: () => {
    return {
      subscriptionPlanId: null,
      hasMultipleAgenda: false,
      companyInfoIsInitialized: false,

      initialTourCompleted: false,
      type: null,

      data: {},
    };
  },
  getters: {
    hasCompanyData: state => !Object.keys(state.data).length == 0,
    get(state) {
      const companyId = localStorage.getItem("company-id");
      if (state.data.length > 0) {
        return state.data.find(company => company.id === companyId);
      } else {
        return state.data.id == companyId ? state.data : null;
      }
    },
    getSingleCompany(state) {
      return state.data;
    },
    // !!!: TODO: restrict in backend...
    subscriptionExpired() {
      const company = this.get;

      const subscriptionEndDate = company?.subscriptionEndDate;
      if (!subscriptionEndDate) return false;

      // TODO: take comparation date from server side
      return moment().isAfter(moment(new Date(subscriptionEndDate)), "day");
    },
    subscriptionTrialLeft() {
      const company = this.get;

      const subscriptionEndDate = company?.subscriptionEndDate;
      if (!subscriptionEndDate) return false;

      // TODO: take comparation date from server side
      const trialDays =
        moment(new Date(subscriptionEndDate)).startOf("day").diff(moment().startOf("day"), "days") +
        1;

      return trialDays;
    },
    isTypeService() {
      return this.type == companyTypes.SERVICE;
    },
    isTypeSubscription() {
      return this.type == companyTypes.SUBSCRIPTION;
    },
    isTypeServiceSubscription() {
      return this.type == companyTypes.SERVICE_SUBSCRIPTION;
    },
  },
  actions: {
    async getCompany() {
      const response = await APICompanies.get();

      this.data = response.data;

      if (import.meta.env.PROD) {
        Sentry.setUser({
          email: response.data.user.email,
        });

        Sentry.setContext("company", {
          id: response.data.id,
          subscriptionPlanId: response.data.subscriptionPlanId,
        });
      }

      this.type = response.data.type;
      this.subscriptionPlanId = response.data.subscriptionPlanId;

      this.initialTourCompleted = !!response.data.user.initialTourCompleted;

      // HACK: no agendas???
      if (
        this.type !== companyTypes.SUBSCRIPTION &&
        Array.isArray(response.data?.agendas) &&
        response.data.agendas.length > 0
      ) {
        const agendasStore = useAgendasStore();
        agendasStore.data = response.data.agendas;

        const eventsStore = useEventsStore();
        const searchStore = useSearchStore();

        // ???: confusing and kind of duplicate as in EventView mounted function
        this.hasMultipleAgenda = response.data.agendas.length > 1;
        if (!this.hasMultipleAgenda) {
          const eventsStore = useEventsStore();
          eventsStore.setCurrentAgendaId(response.data.agendas[0].id);

          agendasStore.currentAgendaPreferredCalendarView = agendasStore.getAgendaCalendarView(
            response.data.agendas[0].id
          );

          // NOTE: When one agenda, use preferredCalendarView by default or the already selected view
          let calendarCurrentView = agendasStore.currentAgendaPreferredCalendarView;

          const selectedCalendarCurrentView = localStorage.getItem(
            "agenda-selected-calendar-current-view"
          );
          if (
            selectedCalendarCurrentView &&
            checkIfIsValidAgendaView(selectedCalendarCurrentView)
          ) {
            calendarCurrentView = selectedCalendarCurrentView;
          }

          searchStore.updateCalendarCurrentView(calendarCurrentView);
        } else {
          const currentAgendaId = eventsStore.currentAgendaId;
          const currentAgenda = agendasStore.get(currentAgendaId);

          if (currentAgendaId !== 0 && !currentAgenda)
            eventsStore.setCurrentAgendaId(response.data.agendas[0].id);

          // NOTE: When multiple agendas, use multipleAgendaTableView by default or the already selected view
          let calendarCurrentView = "multipleAgendaTableView";
          if (currentAgenda) {
            // NOTE: if currentAgendaId is already set & currentAgenda is present, use preferredCalendarView by default or the already selected view
            agendasStore.currentAgendaPreferredCalendarView =
              agendasStore.getAgendaCalendarView(currentAgendaId);
            calendarCurrentView = agendasStore.currentAgendaPreferredCalendarView;
          }

          const selectedCalendarCurrentView = localStorage.getItem(
            "agenda-selected-calendar-current-view"
          );
          if (
            selectedCalendarCurrentView &&
            (currentAgendaId === 0 || currentAgenda) &&
            (currentAgendaId
              ? checkIfIsValidAgendaView(selectedCalendarCurrentView)
              : checkIfIsValidMultipleAgendasView(selectedCalendarCurrentView))
          ) {
            calendarCurrentView = selectedCalendarCurrentView;
          }

          searchStore.updateCalendarCurrentView(calendarCurrentView);
        }
      }

      const companySettingsStore = useCompanySettingsStore();
      companySettingsStore.data = response.data.companySettings;

      const settingTimezone = companySettingsStore.getOneCompanySettingByName(
        companySettingsNames.SETTING_TIMEZONE
      );
      setMomentDefaultTimezone(settingTimezone?.value);

      if (Array.isArray(response.data?.eventTypes) && response.data.eventTypes.length > 0) {
        const eventTypesStore = useEventTypesStore();
        eventTypesStore.data = response.data.eventTypes;
      }

      if (response.data.subscriptionPlan) {
        const subscriptionPlansStore = useSubscriptionPlansStore();
        subscriptionPlansStore.insertOrUpdate(response.data.subscriptionPlan);
      }

      // ???: ? ?? ???
      if (Array.isArray(response.data?.subscriptions) && response.data.subscriptions.length > 0) {
        const subscriptionsStore = useSubscriptionsStore();
        subscriptionsStore.data = response.data.subscriptions;
      }

      const usersStore = useUsersStore();
      usersStore.me = response.data.user;
    },
    requestSubscriptionChange(payload) {
      return APICompanies.request_subscription_change(
        payload.subscriptionPlanId,
        payload.isCanceled
      ).then(response => {
        if (this.data.length > 0) {
          const index = this.data.findIndex(item => item.id == response.data.id);
          this.data[index] = response.data.id;
        } else {
          this.data = response.data;
        }
        this.companyInfoIsInitialized = true;
        this.subscriptionPlanId = response.data.subscriptionPlanId;
      });
    },

    clearFutureSubscription() {
      return APICompanies.clearFutureSubscription().then(res => {
        this.insertOrUpdate(res.data);
      });
    },
    cancelSubscription() {
      return APICompanies.cancelSubscription().then(res => {
        this.insertOrUpdate(res.data);
      });
    },
    downgradeSubscription(payload) {
      return APICompanies.downgradeSubscription(payload).then(res => {
        this.insertOrUpdate(res.data);
      });
    },

    selectSubscriptionPlanInSignupWizard(payload) {
      return APICompanies.selectSubscriptionPlanInSignupWizard(payload).then(response => {
        this.data = response.data;
        // ???: ..
        this.subscriptionPlanId = response.data.subscriptionPlanId;
      });
    },
    update(payload) {
      return APICompanies.put(payload).then(res => {
        this.insertOrUpdate(res.data);
      });
    },
    completeSignUpWizard(object) {
      return new Promise((resolve, reject) => {
        APICompanies.completeSignUpWizard(object)
          .then(res => {
            const authStore = useAuthStore();
            authStore.setAuthInfo({
              accessToken: res.data.accessToken,
              refreshToken: res.data.refreshToken,
            });
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    completeSignUpWizardWithExternalAuth(object) {
      return new Promise((resolve, reject) => {
        APICompanies.completeSignUpWizardWithExternalAuth(object)
          .then(res => {
            const authStore = useAuthStore();
            authStore.setAuthInfo({
              accessToken: res.data.accessToken,
              refreshToken: res.data.refreshToken,
            });
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    completeInitialTour() {
      return APICompanies.completeInitialTour().then(() => {
        this.initialTourCompleted = true;
      });
    },
    updateSubdomain(subdomain) {
      return APICompanies.updateSubdomain(subdomain).then(res => {
        this.insertOrUpdate(res.data);
      });
    },
    insertOrUpdate(object) {
      if (!object) return;

      let index = null;
      if (this.data.length > 0) {
        index = this.data.findIndex(item => item.id == object.id);
      }
      if (index == "-1") {
        this.data.push(object);
      } else if (index) {
        this.data[index] = { ...this.data[index], ...object };
      } else {
        this.data = { ...this.data, ...object };
      }
    },
    delete(object) {
      let index = null;
      if (this.data.length > 0) {
        index = this.data.findIndex(item => item.id == object.id);
      }
      if (index != "-1" || index) {
        this.data.splice(index, 1);
      } else {
        this.data = [];
      }
    },
    apiInvoiceSaveKeys(invoiceClientType, invoiceSeriesName, invoiceClientId, invoiceClientSecret) {
      APICompanies.saveInvoiceProviderKeys(
        invoiceClientType,
        invoiceSeriesName,
        invoiceClientId,
        invoiceClientSecret
      ).then(res => {
        const { alertToastSuccess } = useAlert();
        alertToastSuccess({
          title: "Setarea a fost actualizată",
        });
        this.data.invoiceClientType = res.data.invoiceClientType;
        this.data.invoiceSeriesName = res.data.invoiceSeriesName;
        this.data.invoiceClientId = res.data.invoiceClientId;
        this.data.invoiceClientSecret = res.data.invoiceClientSecret;
      });
    },
  },
});
